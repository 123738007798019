 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="600"
        @hide="$emit('hide')"
        class="snooze-modal"
    >
        <div slot="body">
            <form @submit.prevent="">
                <div class="flex">
                   <div class="flex items-center cursor-pointer">
                        <label v-if="!disabledSnooze" for="snooze" class="text-gray-700 font-medium relative flex flex__align-center mr-4">
                            <span class="leading-tight color-dark f-size-16 truncate mr-3 vcheckbox-title">Daily pickup snoose</span>

                            <div class="relative">
                                <input
                                    v-model="snooze"
                                    :value="0"
                                    id="snooze"
                                    type="radio"
                                    class="hidden"
                                >
                                <div class="toggle__line w-10 h-5 bg-gray-300 rounded-full mr-2" />
                                <div class="toggle__dot absolute w-4 h-4 bg-white rounded-full inset-y-0" />
                            </div>
                        </label>

                        <label for="reminder" class="text-gray-700 font-medium relative flex flex__align-center mr-4">
                            <span class="leading-tight color-dark f-size-16 truncate mr-3 vcheckbox-title">Reminder</span>

                            <div class="relative">
                                <input
                                    v-model="snooze"
                                    :value="1"
                                    id="reminder"
                                    type="radio"
                                    class="hidden"
                                >
                                <div class="toggle__line w-10 h-5 bg-gray-300 rounded-full mr-2" />
                                <div class="toggle__dot absolute w-4 h-4 bg-white rounded-full inset-y-0" />
                            </div>
                        </label>
                    </div>
                </div>

                <div class="flex mt-8">
                    <modal-field label="Alarm date" required class="w-full pr-3">
                        <date-picker :disable-past="true" v-model="date"></date-picker>
                    </modal-field>

                    <modal-field label="Time" required class="w-full pl-3">
                        <div class="flex">
                            <multiselect id="hour" name="hour" placeholder="" v-model="time" :options="hours"></multiselect>
                            <multiselect id="minute" name="minute" placeholder="" v-model="minute" :options="minutes" class="ml-2"></multiselect>
                        </div>
                    </modal-field>
                </div>

                <div class="mt-4">
                    <small class="color-grey">* Date will be sent according to your local time</small>
                </div>

                <div v-if="textAlert" class="mt-1">
                    <small class="color-grey">* {{ textAlert }}</small>
                </div>
            </form>
        </div>

        <div slot="footer" class="w__100-p border__top-grey-1 pt-6">
            <div class="flex justify-end w-full">
                <button
                    class="px-8 btn-primary mr-4"
                    :disabled="!validate"
                    @click="fetch"
                >Apply changes</button>

                <button
                    @click="$emit('hide')"
                    class="px-8 btn-grey-outline"
                >Cancel</button>
            </div>
        </div>
    </modal>
</template>
<script>
import DatePicker from '~/components/DatePicker';
import moment from 'moment';
import axios from 'axios';

export default {
    components: {
        DatePicker,
    },

    props: {
        uuids: {
            type: Array,
            default: () => { return [] },
        }
    },

    name: 'SnoozeModal',

    data() {
        return {
            date: null,
            time: null,
            minute: null,
            snooze: 0,
        }
    },

    computed: {
        textAlert() {
            const result = this.uuids.find(item => {
                return item.expiredReminder || item.activeReminder || item.activeSnooze;
            });

            if (!result) {
                return null;
            }

            if (this.uuids.length > 1 && result) {
                return 'All reminders and snoozes will be finished';
            }

            if (result.activeSnooze) {
                return 'Current active snooze will be finished';
            }

            if (result.activeReminder) {
                return 'Current active reminder will be finished';
            }

            if (result.expiredReminder) {
                return 'Current expired reminder will be finished';
            }

            return null;
        },

        disabledSnooze() {
            return this.uuids.filter(item => {
                return item.status != 'PRE_TRANSPORT' && item.status != 'CREATED';
            }).length;
        },

        hours() {
            let hours = [];
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            for (let index = 0; index < 24; index++) {
                let hour = index;
                hours.push(zeroPad(hour, 2));
            }
            return hours;
        },

        minutes() {
            let minutes = ['00', '10', '20', '30', '40', '50'];
            return minutes;
        },

        validate() {
            return this.date && this.time && this.minute;
        },

        sendValue() {
            const date = moment(this.date).format('YYYY-MM-DD');

            const value = moment(`${date} ${this.time || '00'}:${this.minute || '00'}`).format('YYYY-MM-DD HH:mm');

            return moment(value).format();
        },

        uuidsSend() {
            return this.uuids.map(item => { return item.uuid });
        },

        currentDate() {
            return moment();
        },
    },

    methods: {
        async fetchSnooze() {
            try {
                await axios.post(this.$apiUrl.consignments.snooze, { uuids: this.uuidsSend, ends_at: this.sendValue });

                this.$snotify.success('Successfully')

                this.$emit('success');
                this.$emit('hide');
            } catch(error) {
                this.$snotify.error(error?.response?.data?.errors?.ends_at?.[0] || 'Failed');
            }
        },

        async fetchReminder() {
            try {
                await axios.post(this.$apiUrl.consignments.reminders, { uuids: this.uuidsSend, ends_at: this.sendValue });

                this.$snotify.success('Successfully')

                this.$emit('success');
                this.$emit('hide');
            } catch(error) {
                this.$snotify.error(error?.response?.data?.errors?.ends_at?.[0] || 'Failed');
            }
        },

        async fetch() {
            if (!this.validate) {
                return;
            }

            if (!this.snooze) {
                await this.fetchSnooze();

                return;
            }

            await this.fetchReminder();
        },
    },

    created() {
        const date = new Date(new Date().setMilliseconds(1 * 60 * 60 * 1000));

        this.date = moment(date);
        this.time = date.getHours();
        this.minute = this.minutes[0];

        if (this.disabledSnooze) {
            this.snooze = 1;
        }
    }
}
</script>

<style lang="scss">
.snooze-modal {
    .card-header {
        display: none;
    }

    .modal__footer {
        padding-top: 16px;
    }

    .modal__body {
        padding-top: 50px;
        overflow: visible;

        select {
            border-radius: 0.25rem;
        }

        .toggle__dot {
            top: .13rem;
            left: .13rem;
            transition: all 0.3s ease-in-out;
        }

        .toggle__line {
            transition: all 0.3s ease-in-out;
        }

        input:checked ~ .toggle__line {
            @apply bg-gray-800;
        }

        input:checked ~ .toggle__dot {
            transform: translateX(125%);
        }

        input:checked ~ .no-branding.toggle__line {
            @apply bg-blue-900 #{!important};
        }
    }
}
</style>
